// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component129Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_0_onClick = async (e) => {
                try{ 
                      navigate('/main');
                }
                catch(e){
                  console.log("Error bloc0_1_0_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "p-2"}     > <div id='bloc0_0'  className={ "w-full p-2 bg-white flex flex-col items-center"}     > <img  id='bloc0_0_0'  className={ "bg-white rounded-xl w-full md:w-1/2   object-cover"}  src="Nouveau Template"   alt="undefined" />
            <div id='bloc0_0_1'  className={ "p-2 font-bold text-xl mt-4 mb-4"}     >{`Caddy Chef`} </div></div>
          <div id='bloc0_1'  className={ "p-2 flex flex-col gap-2 mt-10"}     > <Link    to="/main"><button id='bloc0_1_0'  className={ "p-2 "}    undefined= { undefined } onClick = { bloc0_1_0_onClick } >{`Rejoindre une liste`} </button></Link>
            <button id='bloc0_1_1'  className={ "p-2 "}     >{`Créer une liste`} </button></div></div><div id='bloc1'  className={ "p-2 w-full"}     > <div id='bloc1_0'  className={ "w-full p-2 bg-white font-bold flex items-center justify-center"}     >{`Transformez vos idées en listes prêtes à shopper`} </div></div></>
  
}

export default Component129Page;
