// Imports
          import { Link } from 'react-router-dom'
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component137Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_0_1_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_0_1_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "p-2 m-2 bg-gray-100 w-full"}     > <div id='bloc0_0'  className={ "p-2 flex gap-2"}     > <FontAwesomeIcon  id='bloc0_0_0'  icon={ fa.faAlignJustify}      className={ "p-2 "}  />
            <Link    to="/home"><div id='bloc0_0_1'  className={ "w-full p-2 font-bold text-blue-800 rounded-xl shadow bg-gray-200 flex justify-center"}    onClick = { bloc0_0_1_onClick } >{`Caddy Chef`} </div></Link>
            <FontAwesomeIcon  id='bloc0_0_2'  icon={ fa.faPeopleGroup}      className={ "p-2 "}  /></div></div><div id='bloc1'  className={ "p-2 flex flex-col gap-2"}     > <div id='bloc1_0'  className={ "w-full flex flex-col gap-2 bg-gray-100 rounded-xl p-8"}     > <img  id='bloc1_0_0'  className={ "p-8 shadow rounded-xl   object-cover"}  src="https://fs.appisyou.com/users/16/Logo appli courses1731921020519.png"   alt="undefined" />
            <div id='bloc1_0_1'  className={ "p-2 shadow flex justify-center"}     >{`titre de la recette`} </div>
            <FontAwesomeIcon  id='bloc1_0_2'  icon={ fa.faEye}      className={ "p-2 "}  /></div>
          <div id='bloc1_1'  className={ "p-2 flex"}     > <div id='bloc1_1_0'  className={ "p-2 w-2/4 flex flex-col items-center gap-2"}     > <FontAwesomeIcon  id='bloc1_1_0_0'  icon={ fa.faShoppingCart}      className={ "p-2 w-1/4"}  />
              <div id='bloc1_1_0_1'  className={ "p-2 w-2/3"}     > </div></div>
            <div id='bloc1_1_1'  className={ "p-2 w-2/4 flex flex-col items-center gap-2"}     > <FontAwesomeIcon  id='bloc1_1_1_0'  icon={ fa.faShop}      className={ "p-2 w-1/4"}  />
              <div id='bloc1_1_1_1'  className={ "p-2 w-2/3"}     > </div></div></div></div><div id='bloc2'  className={ "p-2 w-full"}     > <div id='bloc2_0'  className={ "w-full p-2 bg-white font-bold flex items-center justify-center"}     >{`Transformez vos idées en listes prêtes à shopper`} </div></div></>
  
}

export default Component137Page;
