import "../../App.css";

import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";


import { useEffect, useState } from "react";
import { getAuthUser } from "../../service/mftc-api";


import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import NotFound from "./pages/notfound/NotFound";
import Component129Page from "./pages/Component129Page/Component129Page";
import Component137Page from "./pages/Component137Page/Component137Page";


function AppClient() {



  const [authUser, setAuthUser] = useState(getAuthUser());









  useEffect(() => {
    serviceWorkerRegistration.updateRegister();
  }, []);


  const Init = () => {

      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(display-mode: fullscreen)").matches;

if (window.navigator.standalone || isStandalone) {
                    return  !authUser ? <Component129Page/>    : <Component129Page/>
                          } else {
                    return  !authUser ? <Component129Page/>    : <Component129Page/>
                          }
  };


  return (

      <BrowserRouter>
        <Routes>
          <Route path="" element={<Init />} />
          <Route path="/" element={<Init />} />
          {
            }<Route path="/home" element={<Component129Page/>}/>
<Route path="/main" element={<Component137Page/>}/>
{
          }
          
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

  );
}

export default AppClient;



